var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", class: { loading: _vm.model.pageLoading } },
    [
      _c("div", { staticClass: "breadcrumbs" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorDashboard" } } },
                [_vm._v("ダッシュボード")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c("router-link", { attrs: { to: { name: "CreatorChannel" } } }, [
                _vm._v("サイト"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c("span", [_vm._v("基本情報の編集")]),
            ],
            1
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [_vm._v("サイトID")]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.channelId.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.channelId.hidden },
              },
              [_vm._m(0)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("span", [_vm._v("https://" + _vm._s(_vm.domain) + "/")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.channelId.value,
                expression: "model.channelId.value",
              },
            ],
            class: { error: _vm.model.channelId.isError },
            attrs: { type: "text" },
            domProps: { value: _vm.model.channelId.value },
            on: {
              change: function ($event) {
                return _vm.validationCheck("channelId")
              },
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model.channelId, "value", $event.target.value)
                },
                function ($event) {
                  return _vm.validationCheck("channelId", true)
                },
              ],
            },
          }),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.channelId.isError },
            },
            _vm._l(_vm.model.channelId.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.channelId.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [_vm._v("サイト名")]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.channelName.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.channelName.hidden },
              },
              [_vm._m(1)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.channelName.value,
                expression: "model.channelName.value",
              },
            ],
            staticClass: "width-300",
            class: { error: _vm.model.channelName.isError },
            attrs: { type: "text" },
            domProps: { value: _vm.model.channelName.value },
            on: {
              change: function ($event) {
                return _vm.validationCheck("channelName")
              },
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model.channelName, "value", $event.target.value)
                },
                function ($event) {
                  return _vm.validationCheck("channelName", true)
                },
              ],
            },
          }),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.channelName.isError },
            },
            _vm._l(_vm.model.channelName.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.channelName.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [_vm._v("サイトロゴ画像")]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.channelLogo.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.channelLogo.hidden },
              },
              [_vm._m(2)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "fileupload" }, [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "image" }, [
                _vm.model.channelLogo.base64Src != null
                  ? _c("img", {
                      attrs: { src: _vm.model.channelLogo.base64Src },
                    })
                  : _vm._e(),
                _vm.model.channelLogo.defaultFileImageUrl != null
                  ? _c("img", {
                      attrs: { src: _vm.model.channelLogo.defaultFileImageUrl },
                    })
                  : _vm._e(),
                !_vm.model.channelLogo.isSetSrc
                  ? _c("span", [_vm._v("ファイルを選択してください")])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [
              _vm.model.channelLogo.loacalFilePath != null
                ? _c(
                    "span",
                    { staticClass: "path" },
                    [
                      _vm._v(_vm._s(_vm.model.channelLogo.loacalFilePath)),
                      _c("fa-icon", {
                        attrs: { icon: "times" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteImage(
                              _vm.model.channelLogo,
                              "model.channelLogo"
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.elementClick("model.channelLogo")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "folder-open" } })],
                    1
                  ),
                  _c("span", [_vm._v("ファイルを選択")]),
                ]
              ),
              _c("input", {
                attrs: {
                  type: "file",
                  id: "model.channelLogo",
                  accept: "image/*",
                },
                on: {
                  input: function ($event) {
                    return _vm.changeImage(
                      $event,
                      _vm.model.channelLogo,
                      "channelLogo"
                    )
                  },
                },
              }),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.channelLogo.isError },
            },
            _vm._l(_vm.model.channelLogo.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.channelLogo.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [_vm._v("ナビゲーション")]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.navigation.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.navigation.hidden },
              },
              [_vm._m(3)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "navigation" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "navigation-list", tag: "ul" } },
                  _vm._l(_vm.model.navigation.items, function (item1) {
                    return _c(
                      "li",
                      {
                        key: item1.key,
                        staticClass: "active",
                        class: { active: item1.isSelected },
                        staticStyle: {
                          "{\n                  transition": "all 1s",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left",
                            on: {
                              click: function ($event) {
                                return _vm.selectNavigation(item1)
                              },
                            },
                          },
                          [
                            item1.isSelected
                              ? _c("fa-icon", {
                                  attrs: { icon: ["far", "dot-circle"] },
                                })
                              : _c("fa-icon", {
                                  attrs: { icon: ["far", "circle"] },
                                }),
                            item1.title.value.length > 0
                              ? _c("span", [_vm._v(_vm._s(item1.title.value))])
                              : _c("span", [_vm._v("[未設定]")]),
                            item1.isError
                              ? _c("fa-icon", {
                                  staticClass: "error",
                                  attrs: { icon: "exclamation-circle" },
                                })
                              : _vm._e(),
                            item1.isSelected
                              ? _c("span", { staticClass: "editing" }, [
                                  _vm._v("編集中"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "right" }, [
                          _c(
                            "button",
                            {
                              staticClass: "updown",
                              attrs: { disabled: !item1.isUp },
                              on: {
                                click: function ($event) {
                                  return item1.up()
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "angle-up" } })],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "updown",
                              attrs: { disabled: !item1.isDown },
                              on: {
                                click: function ($event) {
                                  return item1.down()
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "angle-down" } })],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "delete",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteNavigation(item1)
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "times" } })],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "button",
                  { on: { click: _vm.addNavigation } },
                  [_c("fa-icon", { attrs: { icon: "plus" } })],
                  1
                ),
              ],
              1
            ),
            _vm.model.navigation.isSelected
              ? _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "body" }, [
                    _c("div", { staticClass: "formGroupV2" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "row1" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("リンクタイトル"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "help-button",
                              on: {
                                click: function ($event) {
                                  return _vm.model.helps.navigationLinkTitle.switch()
                                },
                              },
                            },
                            [
                              _c("fa-icon", {
                                attrs: { icon: ["far", "question-circle"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "help-body",
                              class: {
                                hidden:
                                  _vm.model.helps.navigationLinkTitle.hidden,
                              },
                            },
                            [_vm._m(4)]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.model.navigation.selectedItem.title.value,
                              expression:
                                "model.navigation.selectedItem.title.value",
                            },
                          ],
                          staticClass: "width-300",
                          class: {
                            error:
                              _vm.model.navigation.selectedItem.title.isError,
                          },
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.model.navigation.selectedItem.title.value,
                          },
                          on: {
                            change: function ($event) {
                              _vm.validationCheck(
                                "navigation.items[" +
                                  _vm.model.navigation.items.indexOf(
                                    _vm.model.navigation.selectedItem
                                  ) +
                                  "].title"
                              )
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.navigation.selectedItem.title,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                _vm.validationCheck(
                                  "navigation.items[" +
                                    _vm.model.navigation.items.indexOf(
                                      _vm.model.navigation.selectedItem
                                    ) +
                                    "].title",
                                  true
                                )
                              },
                            ],
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "errors",
                            class: {
                              hidden:
                                !_vm.model.navigation.selectedItem.title
                                  .isError,
                            },
                          },
                          _vm._l(
                            _vm.model.navigation.selectedItem.title.errors,
                            function (item2) {
                              return _c(
                                "div",
                                {
                                  key: _vm.model.navigation.selectedItem.title.errors.indexOf(
                                    item2
                                  ),
                                  staticClass: "error",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item2.message))]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                    _c("hr"),
                    _c("div", { staticClass: "formGroupV2" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "row1" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("移動先"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "help-button",
                              on: {
                                click: function ($event) {
                                  return _vm.model.helps.navigationJumpTo.switch()
                                },
                              },
                            },
                            [
                              _c("fa-icon", {
                                attrs: { icon: ["far", "question-circle"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "help-body",
                              class: {
                                hidden: _vm.model.helps.navigationJumpTo.hidden,
                              },
                            },
                            [_vm._m(5)]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("div", { staticClass: "radio" }, [
                          _c("div", { staticClass: "radio-item pb15" }, [
                            _c("div", { staticClass: "main" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.model.navigation.selectedItem.type,
                                    expression:
                                      "model.navigation.selectedItem.type",
                                  },
                                ],
                                attrs: {
                                  id: "model.navigation.selectedItem.type.url",
                                  name: "model.navigation.selectedItem.type",
                                  type: "radio",
                                  value: "url",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.model.navigation.selectedItem.type,
                                    "url"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.model.navigation.selectedItem,
                                        "type",
                                        "url"
                                      )
                                    },
                                    function ($event) {
                                      _vm.validationCheck(
                                        "navigation.items[" +
                                          _vm.model.navigation.items.indexOf(
                                            _vm.model.navigation.selectedItem
                                          ) +
                                          "].type",
                                        true
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "model.navigation.selectedItem.type.url",
                                  },
                                },
                                [_vm._v("URL")]
                              ),
                            ]),
                            _c("div", { staticClass: "body" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "formGroupV2",
                                  class: {
                                    disabled:
                                      _vm.model.navigation.selectedItem.type ==
                                      "page",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "body" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.model.navigation.selectedItem
                                              .url.value,
                                          expression:
                                            "model.navigation.selectedItem.url.value",
                                        },
                                      ],
                                      staticClass: "width-300",
                                      class: {
                                        error:
                                          _vm.model.navigation.selectedItem.url
                                            .isError,
                                      },
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.model.navigation.selectedItem.url
                                            .value,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.validationCheck(
                                            "navigation.items[" +
                                              _vm.model.navigation.items.indexOf(
                                                _vm.model.navigation
                                                  .selectedItem
                                              ) +
                                              "].url"
                                          )
                                        },
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.model.navigation.selectedItem
                                                .url,
                                              "value",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            _vm.validationCheck(
                                              "navigation.items[" +
                                                _vm.model.navigation.items.indexOf(
                                                  _vm.model.navigation
                                                    .selectedItem
                                                ) +
                                                "].url",
                                              true
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "errors",
                                        class: {
                                          hidden:
                                            !_vm.model.navigation.selectedItem
                                              .url.isError,
                                        },
                                      },
                                      _vm._l(
                                        _vm.model.navigation.selectedItem.url
                                          .errors,
                                        function (item2) {
                                          return _c(
                                            "div",
                                            {
                                              key: _vm.model.navigation.selectedItem.url.errors.indexOf(
                                                item2
                                              ),
                                              staticClass: "error",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c("fa-icon", {
                                                    attrs: {
                                                      icon: "exclamation-circle",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(item2.message)),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "radio-item pb15" }, [
                            _c("div", { staticClass: "main" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.model.navigation.selectedItem.type,
                                    expression:
                                      "model.navigation.selectedItem.type",
                                  },
                                ],
                                attrs: {
                                  id: "model.navigation.selectedItem.type.page",
                                  name: "model.navigation.selectedItem.type",
                                  type: "radio",
                                  value: "page",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.model.navigation.selectedItem.type,
                                    "page"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.model.navigation.selectedItem,
                                        "type",
                                        "page"
                                      )
                                    },
                                    function ($event) {
                                      _vm.validationCheck(
                                        "navigation.items[" +
                                          _vm.model.navigation.items.indexOf(
                                            _vm.model.navigation.selectedItem
                                          ) +
                                          "].type",
                                        true
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "model.navigation.selectedItem.type.page",
                                  },
                                },
                                [_vm._v("フリーページ")]
                              ),
                            ]),
                            _c("div", { staticClass: "body" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "formGroupV2",
                                  class: {
                                    disabled:
                                      _vm.model.navigation.selectedItem.type ==
                                      "url",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "body" }, [
                                    _c(
                                      "div",
                                      { staticClass: "radio scroll" },
                                      _vm._l(_vm.model.pages, function (item3) {
                                        return _c(
                                          "div",
                                          {
                                            key: _vm.model.pages.indexOf(item3),
                                            staticClass: "radio-item",
                                          },
                                          [
                                            _c("div", { staticClass: "main" }, [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.model.navigation
                                                        .selectedItem.pageId
                                                        .value,
                                                    expression:
                                                      "\n                                    model.navigation.selectedItem.pageId.value\n                                  ",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  id:
                                                    "model.pages[" +
                                                    _vm.model.pages.indexOf(
                                                      item3
                                                    ) +
                                                    "]",
                                                  name: "model.pages",
                                                },
                                                domProps: {
                                                  value: item3.id,
                                                  checked: _vm._q(
                                                    _vm.model.navigation
                                                      .selectedItem.pageId
                                                      .value,
                                                    item3.id
                                                  ),
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.model.navigation
                                                          .selectedItem.pageId,
                                                        "value",
                                                        item3.id
                                                      )
                                                    },
                                                    function ($event) {
                                                      _vm.validationCheck(
                                                        "navigation.items[" +
                                                          _vm.model.navigation.items.indexOf(
                                                            _vm.model.navigation
                                                              .selectedItem
                                                          ) +
                                                          "].pageId"
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for:
                                                      "model.pages[" +
                                                      _vm.model.pages.indexOf(
                                                        item3
                                                      ) +
                                                      "]",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item3.title))]
                                              ),
                                            ]),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "errors",
                                        class: {
                                          hidden:
                                            !_vm.model.navigation.selectedItem
                                              .pageId.isError,
                                        },
                                      },
                                      _vm._l(
                                        _vm.model.navigation.selectedItem.pageId
                                          .errors,
                                        function (item2) {
                                          return _c(
                                            "div",
                                            {
                                              key: _vm.model.navigation.selectedItem.pageId.errors.indexOf(
                                                item2
                                              ),
                                              staticClass: "error",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c("fa-icon", {
                                                    attrs: {
                                                      icon: "exclamation-circle",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(item2.message)),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "errors",
                            class: {
                              hidden:
                                !_vm.model.navigation.selectedItem.isError,
                            },
                          },
                          _vm._l(
                            _vm.model.navigation.selectedItem.errors,
                            function (item2) {
                              return _c(
                                "div",
                                {
                                  key: _vm.model.navigation.selectedItem.errors.indexOf(
                                    item2
                                  ),
                                  staticClass: "error",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item2.message))]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "right not" }, [
                  _c("span", [_vm._v("アイテムを選択してください")]),
                ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.navigation.isError },
            },
            _vm._l(_vm.model.navigation.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.navigation.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "bottom" }, [
        _vm.model.saveButtonCompleted
          ? _c("div", { staticClass: "saveButtonCompleted" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "check" } })],
                1
              ),
              _c("span", [_vm._v("サイト設定を変更しました")]),
            ])
          : _vm._e(),
        !_vm.validationCheck("all", true, true)
          ? _c("div", { staticClass: "saveButtonValidationError" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                1
              ),
              _c("span", [_vm._v("不備のある項目が存在します")]),
            ])
          : _vm._e(),
        _c(
          "button",
          {
            attrs: {
              disabled:
                !_vm.model.saveButtonActive ||
                !_vm.validationCheck("all", true, true),
            },
            on: { click: _vm.save },
          },
          [
            _vm.model.saveButtonLoading
              ? _c("div", { staticClass: "spinner bubble" })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("fa-icon", { attrs: { icon: "save" } })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("span", [_vm._v("サイト設定を適用")])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }