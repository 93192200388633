export class Model {
  public pageLoading: boolean = true;
  public planId: number = 0;
  public saveButtonActive: boolean = true;
  public saveButtonLoading: boolean = false;
  public saveButtonCompleted: boolean = false;
  public channelId: InputText = new InputText(50, 3);
  public channelName: InputText = new InputText(50);
  public channelLogo: InputImage = new InputImage();
  public navigation: InputNavigations = new InputNavigations();
  public pages: Array<Page> = new Array<Page>();
  public helps: Helps = new Helps();
}
export class Page {
  public id: string = "";
  public title: string = "";
}
export class InputText {
  public constructor(maxLength: number, minLength = 0) {
    this.maxLength = maxLength;
    this.minLength = minLength;
  }
  public value: string = "";
  public maxLength: number = 0;
  public minLength: number = 0;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export class Error {
  public constructor(message: string, index: number | null = null) {
    this.message = message;
    this.index = index;
  }
  public message: string;
  public index: number | null;
}
export class Helps {
  public channelId: Help = new Help();
  public channelName: Help = new Help();
  public channelLogo: Help = new Help();
  public navigation: Help = new Help();
  public navigationLinkTitle: Help = new Help();
  public navigationJumpTo: Help = new Help();
}
export class Help {
  public hidden: boolean = true;
  public switch(): void {
    this.hidden = !this.hidden;
  }
}
export class InputImage {
  public get isSetSrc(): boolean {
    return this.base64Src != null || this.defaultFileImageUrl != null;
  }
  public loacalFilePath: string | null = null;
  public defaultFileImageUrl: string | null = null;
  public base64Src: string | null = null;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
  get loacalFileExtension(): string | null {
    if (this.loacalFilePath == null) {
      return null;
    }
    return this.loacalFilePath.slice(
      ((this.loacalFilePath.lastIndexOf(".") - 1) >>> 0) + 2
    );
  }
}
export class InputNavigations {
  public items: Array<InputNavigation> = new Array<InputNavigation>();
  public selectedItem: InputNavigation | null = null;
  get isSelected(): boolean {
    return this.selectedItem != null;
  }
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export class InputNavigation {
  public constructor(navigation: InputNavigations) {
    this.owner = navigation;
    let key = 0;
    for (let i = 0; i <= this.owner.items.length - 1; i++) {
      if (this.owner.items[i].key >= key) {
        key = this.owner.items[i].key + 1;
      }
    }
    this.key = key;
  }
  public owner: InputNavigations | null = null;
  public key: number = 0;
  public id: number | null = null;
  public title: InputText = new InputText(50);
  public type: InputNavigationType = InputNavigationType.url;
  public url: InputText = new InputText(250);
  public pageId: InputText = new InputText(50);
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
  get isSelected(): boolean {
    if (this.owner != null) {
      return this == this.owner?.selectedItem;
    } else {
      return false;
    }
  }
  get isUp(): boolean {
    if (this.owner != null) {
      return this.owner?.items.indexOf(this) != 0;
    } else {
      return false;
    }
  }
  get isDown(): boolean {
    if (this.owner != null) {
      return this.owner.items.indexOf(this) != this.owner.items.length - 1;
    } else {
      return false;
    }
  }
  public up(): void {
    if (this.owner != null) {
      const from = this.owner.items.indexOf(this);

      this.owner.items.splice(
        from - 1,
        2,
        this.owner.items[from + 0],
        this.owner.items[from - 1]
      );
    }
  }
  public down(): void {
    if (this.owner != null) {
      const from = this.owner.items.indexOf(this);

      this.owner.items.splice(
        from,
        2,
        this.owner.items[from + 1],
        this.owner.items[from + 0]
      );
    }
  }
}
export enum InputNavigationType {
  url = "url",
  page = "page",
}
