// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";
import { commonModule } from "@/store/modules/Common";
import AWS from "aws-sdk";

@Component({
  components: {},
})
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public domain: string = process.env.VUE_APP_API_URL as string;
  public created() {
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
  }
  public async mounted() {
    await this.authCheck();
    this.model.pageLoading = true;
    this.loadingOn();
    await this.mainLoading();
    this.loadingOff();
    this.model.pageLoading = false;
  }
  public async mainLoading() {
    let response: any;
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ChannelDefaultEdit/Main`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        this.model.channelId.value = response.channelId;
        this.model.channelName.value = response.channelName;
        this.model.channelLogo.loacalFilePath = null;
        this.model.channelLogo.base64Src = null;
        this.model.channelLogo.defaultFileImageUrl = response.channelLogoUrl;
        this.model.navigation.selectedItem = null;
        this.model.navigation.items = new Array<model.InputNavigation>();
        for (const item of response.navigations) {
          const navigation = new model.InputNavigation(this.model.navigation);
          navigation.id = item.id;
          navigation.title.value = item.title;
          switch (item.type) {
            case "url":
              navigation.type = model.InputNavigationType.url;
              navigation.url.value = item.url;
              break;
            case "page":
              navigation.type = model.InputNavigationType.page;
              navigation.pageId.value = item.pageId.toString();
              break;
          }
          this.model.navigation.items.push(navigation);
        }
        this.model.pages = new Array<model.Page>();
        for (const item of response.pages) {
          this.model.pages.push({
            id: item.id.toString(),
            title: item.title,
          });
        }
      })
      .catch((ex) => {
        throw ex;
      });
  }
  public selectNavigation(item: model.InputNavigation | null): void {
    this.model.navigation.selectedItem = item;
    this.validationCheck("navigation");
  }
  public async addNavigation() {
    this.model.navigation.items.push(
      new model.InputNavigation(this.model.navigation)
    );
  }
  public async deleteNavigation(item: model.InputNavigation) {
    if (!window.confirm("削除しますか？")) {
      return;
    }
    let reSelect = false;
    if (item == this.model.navigation.selectedItem) {
      reSelect = true;
    }
    this.model.navigation.items.splice(
      this.model.navigation.items.indexOf(item),
      1
    );
    if (reSelect) {
      this.selectNavigation(null);
    } else {
      this.validationCheck("navigation");
    }
  }
  public async deleteImage(target: model.InputImage, id: string) {
    if (!window.confirm("削除しますか？")) {
      return;
    }
    target.loacalFilePath = null;
    target.base64Src = null;
    target.defaultFileImageUrl = null;
    const element: any = document.getElementById(id);
    if (element != null) {
      element.value = "";
    }
  }
  public async elementClick(id: string) {
    const element = document.getElementById(id);
    if (element != null) {
      element.click();
    }
  }
  public changeImageTarget: model.InputImage | null = null;
  public changeImageValidationTarget: string | null = null;
  public async changeImage(
    e: any,
    target: model.InputImage,
    validationTarget: string
  ) {
    const file = e.target.files[0];
    if (file == null) {
      return;
    }
    this.changeImageTarget = target;
    this.changeImageValidationTarget = validationTarget;

    this.model.saveButtonActive = false;
    this.changeImageTarget.loacalFilePath = null;
    this.changeImageTarget.defaultFileImageUrl = null;
    this.changeImageTarget.base64Src = null;
    this.changeImageTarget.loacalFilePath = file.name;
    const reader = new FileReader();
    reader.onload = this.changeImageLoaded;
    reader.readAsDataURL(file);
  }
  public changeImageLoaded(event: any) {
    if (this.changeImageTarget == null) {
      return;
    }
    if (this.changeImageValidationTarget == null) {
      return;
    }
    this.changeImageTarget.base64Src = event.target.result;
    this.validationCheck(this.changeImageValidationTarget);
    this.model.saveButtonActive = true;
  }
  public validationCheck(target = "", inputing = false, auto = false): boolean {
    if (!auto && !inputing) {
      this.model.saveButtonCompleted = false;
    }
    let result = true;
    let input: any;
    let errors: any;
    if (target == "all" || target == "channelId") {
      input = this.model.channelId;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = input.value.trim();
        input.value = this.hankaku2Zenkaku(input.value);
      }
      if (input.value.length === 0) {
        errors.push(new model.Error("必須項目です"));
      } else if (input.value.match(/^([A-Za-z0-9]){1}/g) == null) {
        errors.push(
          new model.Error("サイトIDの最初の一文字には記号は使えません")
        );
      } else if (input.value.match(/([A-Za-z0-9]){1}$/g) == null) {
        errors.push(
          new model.Error("サイトIDの最後の一文字には記号は使えません")
        );
      } else if (input.value.length < input.minLength) {
        errors.push(
          new model.Error(`${input.minLength}文字以上で入力してください`)
        );
      } else if (input.value.match(/^([A-Za-z0-9_-])+$/g) == null) {
        errors.push(
          new model.Error(
            "対応していない文字列が使用されています（アルファベット大文字・小文字、ハイフン、アンダーバーが使用可能）"
          )
        );
      }
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "channelName") {
      input = this.model.channelName;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = input.value.trim();
        input.value = this.hankaku2Zenkaku(input.value);
      }
      if (input.value.length === 0) {
        errors.push(new model.Error("必須項目です"));
      } else if (input.value.length < input.minLength) {
        errors.push(
          new model.Error(`${input.minLength}文字以上で入力してください`)
        );
      }
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^navigation\.items\[([0-9]+)\]\.title/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^navigation\.items\[([0-9]+)\]\.title/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.navigation.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.navigation.items[i].title;
        errors = new Array<model.Error>();
        //
        if (!inputing) {
          input.value = input.value.trim();
          input.value = this.hankaku2Zenkaku(input.value);
        }
        if (input.value.length === 0) {
          errors.push(new model.Error("必須項目です"));
        } else if (input.value.length < input.minLength) {
          errors.push(
            new model.Error(`${input.minLength}文字以上で入力してください`)
          );
        }
        if (input.value.length > input.maxLength) {
          errors.push(
            new model.Error(`${input.maxLength}文字以下で入力してください`)
          );
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^navigation\.items\[([0-9]+)\]\.url/g) != null ||
      target.match(/^navigation\.items\[([0-9]+)\]\.type/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^navigation\.items\[([0-9]+)\]/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.navigation.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.navigation.items[i].url;
        errors = new Array<model.Error>();
        //
        if (
          this.model.navigation.items[i].type == model.InputNavigationType.url
        ) {
          if (!inputing) {
            input.value = input.value.trim();
            input.value = this.hankaku2Zenkaku(input.value);
          }
          if (input.value.length === 0) {
            errors.push(new model.Error("必須項目です"));
          } else if (
            input.value.match(/^(ftp|http|https):\/\/[^ "]+$/) == null
          ) {
            errors.push(new model.Error("対応していないURLの形式です"));
          } else if (input.value.length > input.maxLength) {
            errors.push(
              new model.Error(`${input.maxLength}文字以下で入力してください`)
            );
          }
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^navigation\.items\[([0-9]+)\]\.pageId/g) != null ||
      target.match(/^navigation\.items\[([0-9]+)\]\.type/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^navigation\.items\[([0-9]+)\]/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.navigation.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.navigation.items[i].pageId;
        errors = new Array<model.Error>();
        //
        if (
          this.model.navigation.items[i].type == model.InputNavigationType.page
        ) {
          if (input.value.length === 0) {
            errors.push(new model.Error("必須項目です"));
          }
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^navigation\.items\[([0-9]+)\]/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^navigation\.items\[([0-9]+)\]/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.navigation.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.navigation.items[i];
        errors = new Array<model.Error>();
        const isError = false;
        //
        if (input.title.isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
        switch (input.type) {
          case model.InputNavigationType.url:
            if (input.url.isError && errors.length == 0) {
              errors.push(new model.Error("不備のある項目が存在します"));
            }
            break;
          case model.InputNavigationType.page:
            if (input.pageId.isError && errors.length == 0) {
              errors.push(new model.Error("不備のある項目が存在します"));
            }
            break;
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (target == "all" || target.match(/^navigation/g) != null) {
      input = this.model.navigation;
      errors = new Array<model.Error>();
      //
      for (let i = 0; i <= this.model.navigation.items.length - 1; i++) {
        if (this.model.navigation.items[i].isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    return result;
  }
  public async save() {
    this.model.pageLoading = true;
    this.model.saveButtonActive = false;
    this.model.saveButtonLoading = true;
    const validationCheck = this.validationCheck("all");
    if (!validationCheck) {
      await this.sleep(1000);
      this.model.saveButtonLoading = false;
      this.model.saveButtonActive = true;
      this.model.pageLoading = false;
      return;
    }

    this.loadingOn();
    let response: any;
    let channelLogo = null;

    if (this.model.channelLogo.base64Src != null) {
      const channelLogoParts = [];
      try {
        response = await axios.post(
          `https://${process.env.VUE_APP_API_URL}/ChannelDefaultEdit/LogoUpload`,
          {
            extension: this.model.channelLogo.loacalFileExtension,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + (await this.creatorUserManager.getJwtToken()),
            },
          }
        );
        response = response.data;
      } catch (ex) {
        throw ex;
      }

      await this.creatorUserManager.credential();

      const s3 = new AWS.S3({
        params: {
          Region: response.region,
          Bucket: response.bucket,
        },
      });
      const uploadPartResult = await s3
        .uploadPart({
          Bucket: response.bucket,
          Key: response.key,
          UploadId: response.uploadId,
          PartNumber: 1,
          Body: this.base64toUint8Array(
            this.model.channelLogo.base64Src.split(",")[1]
          ),
        })
        .promise();
      channelLogoParts.push({
        partNumber: 1,
        eTag: uploadPartResult.ETag,
      });

      channelLogo = {
        uploadId: response.uploadId,
        parts: channelLogoParts,
        key: response.key,
      };
    }

    {
      const navigations = [];
      for (const item of this.model.navigation.items) {
        switch (item.type.toString()) {
          case "url":
            navigations.push({
              id: item.id,
              title: item.title.value,
              type: item.type.toString(),
              url: item.url.value,
              pageId: null,
            });
            break;
          case "page":
            navigations.push({
              id: item.id,
              title: item.title.value,
              type: item.type.toString(),
              url: "",
              pageId: Number(item.pageId.value),
            });
            break;
        }
      }
      await axios
        .post(
          `https://${process.env.VUE_APP_API_URL}/ChannelDefaultEdit/Save`,
          {
            channelId: this.model.channelId.value,
            channelName: this.model.channelName.value,
            channelLogo: channelLogo,
            navigations: navigations,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + (await this.creatorUserManager.getJwtToken()),
            },
          }
        )
        .then(async (response) => {
          response = response.data;
          this.model.saveButtonCompleted = true;
          commonModule.setChannelName(this.model.channelName.value);
          await this.mainLoading();
        })
        .catch((ex) => {
          throw ex;
        });
    }
    this.loadingOff();
    this.model.saveButtonActive = true;
    this.model.saveButtonLoading = false;
    this.model.pageLoading = false;
  }
}
